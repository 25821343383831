// STYLES ===================
import './styles/main.css';
import './styles/assessments.css';

// ICONS ====================
import { dustbinSVG, keyEncrypt, passwordEye, FBX, warningSVG, succSVG, warningSuccSVGs, duplicateSVG, addSer, dSVG, leftArrowSVG, rightArrowSVG, mfSVG, cfSVG, cdrSVG, iSVG, tickCircleSVG, crossCircleSVG, linkOpenTabSVG } from './js/svgIcons';

// PAGES ====================
import './pages/addApplications.html';
import './pages/addReport.html';
import './pages/addUser.html';
import './pages/adminApplications.html';
import './pages/adminReports.html';
import './pages/angloApplications.html';
import './pages/applications.html';
import './pages/arep_AADBBilling.html';
import './pages/arep_adminReports.html';
import './pages/arep_appstatus.html';
import './pages/arep_assbooksla.html';
import './pages/arep_asspurpose.html';
import './pages/arep_asssla.html';
import './pages/arep_assstatus.html';
import './pages/arep_jobs.html';
import './pages/arep_repdel.html';
import './pages/arep_reportFailures.html';
import './pages/arep_reportRegister.html';
import './pages/arep_reportReview.html';
import './pages/assignAssessment.html';
import './pages/AUt_addUser.html';
import './pages/AUt_bulkUser.html';
import './pages/AUt_linkBulkUser.html';
import './pages/CFs_email.html';
import './pages/CFs_SMS.html';
import './pages/ConsentForm1.html';
import './pages/ConsentForm2.html';
import './pages/contactUs.html';
import './pages/editJobs.html';
import './pages/editReports.html';
import './pages/editUser.html';
import './pages/eFeedback.html';
import './pages/EUs_CPP.html';
import './pages/EUs_EIP.html';
import './pages/EUs_EQi.html';
import './pages/EUs_HDS.html';
import './pages/EUs_MCPA.html';
import './pages/EUs_MCPAscan.html';
import './pages/EUs_OPQ.html';
import './pages/EUs_verify.html';
import './pages/EUt_allocateClient.html';
import './pages/EUt_busEntAlloc.html';
import './pages/EUt_developmentPlan.html';
import './pages/EUt_LMS.html';
import './pages/EUt_messageBox.html';
import './pages/EUt_navigation.html';
import './pages/EUt_profile.html';
import './pages/EUt_projects.html';
import './pages/EUt_reports.html';
import './pages/EUt_results.html';
import './pages/EUt_userApprovals.html';
import './pages/EUt_userAssessments.html';
import './pages/EUt_userGroups.html';
import './pages/formTemplate.html';
import './pages/homePage.html';
import './pages/JFt_costCenter.html';
import './pages/JFt_family.html';
import './pages/JFt_jobLevels.html';
import './pages/JFt_publication.html';
import './pages/JFt_servicePerBisinessUnit.html';
import './pages/jobSpec.html';
import './pages/landingPage1.html';
import './pages/landingPageAbsa.html';
import './pages/landingPageAnglo.html';
import './pages/landingPageAngloAmerican.html';
import './pages/landingPageBasrus.html';
import './pages/landingPageCapitecClient.html';
import './pages/landingPageDiscovery.html';
import './pages/landingPageIntegrate.html';
import './pages/landingPageIWPR_LogedIn.html';
import './pages/landingPageJVRRatee.html';
import './pages/landingPageJVRRater.html';
import './pages/landingPagePS_LogedIn.html';
import './pages/landingPageShopriteRatee.html';
import './pages/landingPageShopriteRater.html';
import './pages/landingPageVitality.html';
import './pages/login.html';
import './pages/loginContactUs.html';
import './pages/loginSupport.html';
import './pages/manageApplications.html';
import './pages/manageJobs.html';
import './pages/manageReports.html';
import './pages/manageUsers.html';
import './pages/MAt_assessments.html';
import './pages/MAt_consent.html';
import './pages/MAt_current.html';
import './pages/MAt_guideAnglo.html';
import './pages/MAt_guideBasrus.html';
import './pages/MAt_guideDISC.html';
import './pages/MAt_reports.html';
import './pages/MJt_applications.html';
import './pages/MJt_ass.html';
import './pages/MJt_comAss.html';
import './pages/MJt_jobSpecs.html';
import './pages/MJt_reports.html';
import './pages/MRt_headers.html';
import './pages/MRt_msgEvents.html';
import './pages/MRt_userRoles.html';
import './pages/MUt_advSearch.html';
import './pages/MUt_compareUsers.html';
import './pages/MUt_users.html';
import './pages/myassessments.html';
import './pages/myDevelopment.html';
import './pages/rar_edit.html';
import './pages/recordAngloApp.html';
import './pages/recordJob.html';
import './pages/reportAutoRules.html';
import './pages/resourceRegister.html';
import './pages/sanitizeApplications.html';
import './pages/SAs_headersAA.html';
import './pages/SAs_headersDISC.html';
import './pages/SAs_headersGAP.html';
import './pages/SAt_addAssessments.html';
import './pages/SAt_addServices.html';
import './pages/SAt_contactForm.html';
import './pages/SAt_headers.html';
import './pages/SAt_history.html';
import './pages/SAt_manStatus.html';
import './pages/support.html';
import './pages/SurveyFormatting_AA.html';
import './pages/takeAssessment.html';
import './pages/UNt_guide.html';
import './pages/UNt_history.html';
import './pages/UNt_nominations.html';
import './pages/UPt_myCV.html';
import './pages/UPt_myMessages.html';
import './pages/UPt_profile.html';
import './pages/userNominations.html';
import './pages/userProfile.html';
import './pages/userSelections.html';
import './pages/USt_guide.html';
import './pages/USt_selections.html';
import './pages/viewAngloApp.html';
import './pages/viewAppDetails.html';
import './pages/viewApplication.html';
import './pages/monitoring.html';
import './pages/MOt_reports.html';
// CORE ======================
import { id, initSession, createCookie, insertDiv, btnModalOn, setDevProf, PREP, btnModalOff, loader, eListen } from './js/core';

//CONFIG =====================
import { domainPreFix, clientThemes } from './js/config';

// CANDI =====================
import { login } from './js/candi';

// IMAGES
//Logos
import './assets/img/clientLogos/clientLogo_aa.svg';
import './assets/img/clientLogos/clientLogo_aga.svg';
import './assets/img/clientLogos/clientLogo_capitec.svg';
import './assets/img/clientLogos/clientLogo_discovery.svg';
import './assets/img/clientLogos/clientLogo_integrate.svg';
import './assets/img/clientLogos/clientLogo_shoprite.svg';
import './assets/img/clientLogos/clientLogo_vitality.svg';
import './assets/img/clientLogos/integrateLogo_white_Moboile.svg';
import './assets/img/clientLogos/integrateLogo_white.svg';
import './assets/img/clientLogos/integrateLogo_whiteStacked.svg';
//login
import './assets/img/login/loginImage_integrate.png';
import './assets/img/login/loginImage_aa.png';
import './assets/img/login/loginImage_discovery.png';
import './assets/img/login/loginImage_aga.png';
import './assets/img/general/favicon.ico';
// Navigation Logos
import './assets/img/clientNavLogos/clientLogoNav_aa.svg';
import './assets/img/clientNavLogos/clientLogoNav_aga.svg';
import './assets/img/clientNavLogos/clientLogoNav_capitec.svg';
import './assets/img/clientNavLogos/clientLogoNav_discovery.svg';
import './assets/img/clientNavLogos/clientLogoNav_integrate.svg';
import './assets/img/clientNavLogos/clientLogoNav_shoprite.svg';
import './assets/img/clientNavLogos/clientLogoNav_vitality.svg';
//Homepage
import './assets/img/homePage/homepageComputer_integrate.png';
import './assets/img/homePage/homepageComputer_aa.png';
import './assets/img/homePage/homepageComputer_discovery.png';
import './assets/img/homePage/capitec_landingPageImg.png';
//My developemntpage
import './assets/img/myDevelopmet/development-1.png';
import './assets/img/myDevelopmet/development-2.png';
import './assets/img/myDevelopmet/development-3.png';
import './assets/img/myDevelopmet/eFeedbackImg2.png';
// General Images
import './assets/img/icons/eye.svg';
import './assets/img/general/excelExample.png';
import './assets/img/icons/plus_icon.svg';
import './assets/img/icons/dragDots.svg';
import './assets/img/icons/tick.svg';
import './assets/img/icons/cross.svg';
import './assets/img/icons/cross.svg';
import './assets/img/icons/download.svg';
import './assets/img/icons/upload.svg';
// Downloads
import './assets/downloads/applicantsUpload.xlsx';
import './assets/downloads/bulkUploadUsers.xlsx';

// IIFE + LOGIN ===============
(async () => {
  await initSession();

  // const querySearchString =
  //   "https://angloamericananddebeersgroup.ux2.integratezone.com/?resetUUID=29B15647-0E87-421C-AB9AEC5B5EFD55D5&resetHuserID=12115&resetlanguageID=1";

  const querySearchString = window.location.search;
  const queryURLString = window.location.href;
  const urlString = queryURLString.split('#');
  let urlParams;
  if (urlString[1]) {
    urlParams = new URLSearchParams(urlString[1]);
  } else {
    urlParams = new URLSearchParams(querySearchString);
  }

  //-------------------------------------------------
  const languageID = urlParams.get('languageID');
  const resetUUID = urlParams.get('resetUUID');
  const resetHuserID = urlParams.get('resetHuserID');
  const resetlanguageID = urlParams.get('resetlanguageID');
  const accessToken = urlParams.get('access_token');

  if (querySearchString) {
    if (languageID) {
      createCookie('mbohvbhfje', languageID);
    } else {
      createCookie('mbohvbhfje', 1);
    }

    if (resetlanguageID) {
      createCookie('mbohvbhfje', resetlanguageID);
    } else {
      createCookie('mbohvbhfje', 1);
    }

    if (resetUUID) {
      login.validateReset(resetUUID, resetHuserID);
    }
  }

  const setOauthListen = () => {
    if (!id('ssoBtn')) {
      setTimeout(() => {
        setOauthListen();
      }, 100);
    } else {
      eListen(
        'ssoBtn',
        () => {
          login.SSOauth();
        },
        'click'
      );
    }
  };
  setOauthListen();

  // Login Page
  await insertDiv('login', 'primaryContainer');

  if (accessToken) {
    login.SSOauthLogin(`${accessToken}`);
  }

  // Set client theme
  const theme = clientThemes[`${domainPreFix}`] ? clientThemes[`${domainPreFix}`] : clientThemes['integratezone'];
  const root = document.documentElement;
  const setVariables = (vars) => Object.entries(vars).forEach((v) => root.style.setProperty(v[0], v[1]));
  const myVariables = {
    '--primaryColor': `${theme.primaryColor}`,
    '--primaryColorOpacity': `${theme.primaryColorOpacity}`,
    '--secondaryColor': `${theme.secondaryColor}`,
    '--secondaryColorOpacity': `${theme.secondaryColorOpacity}`,
    '--secondaryColorLight': `${theme.secondaryColorLight}`,
    '--thirdColor': `${theme.thirdColor}`,
    '--fourthColour': `${theme.fourthColourfourthColour}`,
    '--darkColour': `${theme.darkColour}`,
  };
  setVariables(myVariables);
})();

export const authLogin2 = () => {
  btnModalOn('loginBtn');
  setDevProf();
  const vars = {};
  vars.username = id('l_username').value.trim();
  vars.userPassword = id('l_password').value.trim();

  PREP(['login'], vars)
    .then((res) => {
      login.setLoggedIn(res.data.login);
      btnModalOff('loginBtn');
      loader(0);
    })
    .catch((err) => {
      console.log(err);
      btnModalOff('loginBtn');
    });
};
window.authLogin2 = authLogin2;
